/* eslint-disable no-var */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//console.log('Hello World from Webpacker')
// Support component names relative to this directory:
// - omit Admin components and any .test.jsx files
var componentRequireContext = require.context('components', true, /^((?!Admin|Jobs|\.test).)*$/)
//console.log(componentRequireContext.keys());

var ReactRailsUJS = require('react_ujs')
/* eslint-disable-next-line react-hooks/rules-of-hooks */
ReactRailsUJS.useContext(componentRequireContext)


// Initialize the redux store
// Normally, with a single page app, we'd do this in a root component -- but our
// components are decentralized, so we do it in the LL global.
const { configureStore } = require('ducks/reducers')
if(!window.LL) {
  window.LL = {}
}
window.LL.store = configureStore()
